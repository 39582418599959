import { AdicionarButton, FormContainer, InputContainer, PedidoCategoriaBebidasContainer, SelectContainer, TableContainer, TitleContainer } from './styles';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import api from '../../services/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/Auth';
import { moneyMask } from '../../utils/masks';
import CustomTable from '../CustomTable';
import PedidoBebidaTr from '../Trs/PedidoBebidaTr';
import { toast } from 'react-toastify';
// import Draggable from "react-draggable";
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaSortAmountDownAlt, FaSortAmountUp } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';

const PedidoCategoriaBebidas = ({ index,categoria, pedido, categoriasBebidasValue, buscarPedidoBebidaCategorias, setCategoriaBebidasLoading, pedidoBebidas, buscarPedidoBebidas, pedidoBebidasLoaded }) => {
    const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues, watch } = useForm({
        resolver: yupResolver(yup.object().shape({
            bebida: yup.object().nullable().required('Campo obrigatório'),
            volume: yup.object().nullable().required('Campo obrigatório'),
            preco: yup.string().required('Campo obrigatório'),
        }))
    });

    const [bebidas, setBebidas] = useState([]);
    const [pedidoBebidasOrdenadas, setPedidoBebidasOrdenadas] = useState([])
    const [pedidoBebidasLoading, setPedidoBebidasLoading] = useState(true);

    const [bebidasOptions, setBebidasOptions] = useState([]);

    const [volumesOptions, setVolumesOptions] = useState([]);
    const [volumesLoading, setVolumesLoading] = useState(false);

    const [promocao, setPromocao] = useState(false);
    const [promocaoValue, setPromocaoValue] = useState(false);

    const { logout, user } = useAuth();
    const { navigate } = useNavigate();

    useEffect(() => {
        if (pedidoBebidasLoaded) {
            const bebidasOrdenadas = pedidoBebidas.sort((a, b) => {
                const precoA = Number(a.preco.replace(',', '.'));
                const precoB = Number(b.preco.replace(',', '.'));
                if (categoria.ordem === 'crescente') {
                    if (precoA > precoB) {
                        return 1
                    } else if (precoA < precoB) {
                        return -1
                    }
                };
                if (categoria.ordem === 'decrescente') {
                    if (precoA < precoB) {
                        return 1
                    } else if (precoA > precoB) {
                        return -1
                    }
                };
                if (categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente') {
                    return Number(a.ordem) - Number(b.ordem)
                };
            });
            setPedidoBebidasOrdenadas(bebidasOrdenadas);
            setPedidoBebidasLoading(false);
        };
    }, [pedidoBebidasLoaded]);

    const buscarBebidas = () => {
        api.get(`/bebidas?categoria=${encodeURI(categoria.label)}`)
            .then((response) => {
                const bebidasFiltradas = response.data.data.bebidas.filter((bebida) => bebida.status);
                const options = bebidasFiltradas.map((bebida) => {
                    return {
                        value: bebida.id,
                        label: bebida.nome
                    };
                });
                setBebidas(bebidasFiltradas);
                setBebidasOptions(options);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarBebidas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (watch('bebida') && watch('volume')) {
            const bebidaComVolume = getValues('bebida').label + ' ' + getValues('volume').label;
            const bebidasPromocao = ['antarctica boa 300ml', 'bohemia 300ml', 'brahma chopp 300ml', 'skol pilsen 300ml'];
            if (bebidasPromocao.includes(bebidaComVolume.toLowerCase())) {
                setPromocao(true);
                setPromocaoValue(true);
                if (user.geo_id === 1) {
                    setValue('preco', '3 por 10,00');
                } else {
                    setValue('preco', '4 por 12,00');
                }
            } else {
                setPromocao(false);
            }
        };
    }, [watch('bebida'), watch('volume')]);

    const buscarVolumes = (bebida) => {
        setVolumesLoading(true);
        api.get(`/bebida-volume-categorias?bebida_id=${bebida.value}&categoria_id=${categoria.value}`)
            .then((response) => {
                const volumes = response.data.data.volumeCategorias.filter((volume) => volume.status);
                const options = volumes.map((volume) => {
                    return {
                        value: volume.volume_id,
                        label: volume.volume
                    };
                });
                setValue('volume', options[0]);
                setVolumesOptions(options);
                setVolumesLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                setVolumesLoading(false);
            });
    };

    const onSubmitFunction = (data) => {
        const bebida = bebidas.find((bebida) => bebida.id === data.bebida.value);
        const ultimaBebida = pedidoBebidas.at(-1);
        const dados = {
            pedido_bebida_categoria_id: categoria.pedido_bebida_categoria_id,
            bebida_id: data.bebida.value,
            volume: data.volume.label,
            preco: data.preco,
            nome: bebida.nome,
            segmento: bebida.segmento,
            embalagem: bebida.embalagem,
            pack: bebida.pack,
            marca: bebida.marca,
            marca_consolid: bebida.marca_consolid,
            ordem: ultimaBebida ? ultimaBebida.ordem+1 : 1,
        };
        api.post(`/pedido-bebidas/${pedido.id}`, dados)
            .then((response) => {
                buscarPedidoBebidas();
                reset();
                setValue('bebida', null);
                setValue('volume', null);
                setPromocao(false);
                setPromocaoValue(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message);
            });
    };

    const handlePosicaoCima = () => {
        setCategoriaBebidasLoading(true);
        let categoriasUpdate = [];
        categoriasBebidasValue.forEach((categoriaBebida) => {
            if (Number(categoriaBebida.posicao) === Number(categoria.posicao)) {
                categoriasUpdate.push({
                    id: categoriaBebida.pedido_bebida_categoria_id,
                    posicao: Number(categoriaBebida.posicao) - 1
                });
            };
            if (Number(categoriaBebida.posicao) === Number(categoria.posicao) - 1) {
                categoriasUpdate.push({
                    id: categoriaBebida.pedido_bebida_categoria_id,
                    posicao: Number(categoriaBebida.posicao) + 1
                });
            };
        });
        api.patch(`/pedido-bebida-categorias/${pedido.id}`, {categorias: categoriasUpdate})
            .then((response) => {
                buscarPedidoBebidaCategorias();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message);
                setCategoriaBebidasLoading(false);
            });
    };

    const handlePosicaoBaixo = () => {
        setCategoriaBebidasLoading(true);
        let categoriasUpdate = [];
        categoriasBebidasValue.forEach((categoriaBebida) => {
            if (Number(categoriaBebida.posicao) === Number(categoria.posicao)) {
                categoriasUpdate.push({
                    id: categoriaBebida.pedido_bebida_categoria_id,
                    posicao: Number(categoriaBebida.posicao) + 1
                });
            };
            if (Number(categoriaBebida.posicao) === Number(categoria.posicao) + 1) {
                categoriasUpdate.push({
                    id: categoriaBebida.pedido_bebida_categoria_id,
                    posicao: Number(categoriaBebida.posicao) - 1
                });
            };
        });
        api.patch(`/pedido-bebida-categorias/${pedido.id}`, {categorias: categoriasUpdate})
            .then((response) => {
                buscarPedidoBebidaCategorias();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message);
                setCategoriaBebidasLoading(false);
            });
    };

    const handleRemoverOrdem = () => {
        setCategoriaBebidasLoading(true);
        const dados = {
            id: categoria.pedido_bebida_categoria_id,
            ordem: 'null',
        };
        api.patch(`/pedido-bebida-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoBebidaCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setCategoriaBebidasLoading(false);
        });
    };

    const handleOrdenarCrescente = () => {
        setCategoriaBebidasLoading(true);
        const dados = {
            id: categoria.pedido_bebida_categoria_id,
            ordem: 'crescente',
        };
        api.patch(`/pedido-bebida-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoBebidaCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setCategoriaBebidasLoading(false);
        });
    };

    const handleOrdenarDecrescente = () => {
        setCategoriaBebidasLoading(true);
        const dados = {
            id: categoria.pedido_bebida_categoria_id,
            ordem: 'decrescente',
        };
        api.patch(`/pedido-bebida-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoBebidaCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setCategoriaBebidasLoading(false);
        });
    };

    return (
        <PedidoCategoriaBebidasContainer>
            <TitleContainer>
                <button
                    disabled={Number(index) === 0}
                    onClick={handlePosicaoCima}
                >
                    <FiArrowUp title='Mover categoria pra cima' stroke='#292828'/>
                </button>
                <button
                    disabled={Number(index+1) === categoriasBebidasValue.length}
                    onClick={handlePosicaoBaixo}
                >
                    <FiArrowDown title='Mover categoria pra baixo' stroke='#292828'/>
                </button>
                <p>
                    {categoria.label}
                </p>
                <div className='sort-itens-container'>
                    <button
                        onClick={handleRemoverOrdem}
                        disabled={categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente'}
                    >
                        <GrFormClose title='Remover ordenação' size={16} />
                    </button>
                    <button
                        onClick={handleOrdenarCrescente}
                        disabled={categoria.ordem === 'crescente'}
                    >
                        <FaSortAmountDownAlt title='Ordenar preço crescente' fill='#292828' size={18} />
                    </button>
                    <button
                        onClick={handleOrdenarDecrescente}
                        disabled={categoria.ordem === 'decrescente'}
                    >
                        <FaSortAmountUp title='Ordenar preço decrescente' fill='#292828' size={18} />
                    </button>
                </div>
            </TitleContainer>
            <FormContainer onSubmit={handleSubmit(onSubmitFunction)}>
                <SelectContainer className='bebida'>
                    <Controller
                        name='bebida'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={bebidasOptions}
                                defaultValue={null}
                                className='react-select-container'
                                classNamePrefix='react-select'
                                placeholder='Selecione um produto'
                                onChange={(value) => {
                                    if (value) {
                                        buscarVolumes(value);
                                    };
                                    setValue('bebida', value);
                                }}
                            /> 
                        )}
                    />
                    {errors.bebida && <span className='input-error'>{errors.bebida.message}</span>}
                </SelectContainer>
                <SelectContainer className='volume'>
                    <Controller
                        name='volume'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={volumesOptions}
                                defaultValue={null}
                                className='react-select-container'
                                classNamePrefix='react-select'
                                isDisabled={!getValues('bebida')}
                                isLoading={volumesLoading}
                                placeholder='Selecione um volume'
                                onChange={(value) => {
                                    setValue('volume', value);
                                }}
                            /> 
                        )}
                    />
                    {errors.volume && <span className='input-error'>{errors.volume.message}</span>}
                </SelectContainer>
                <InputContainer className='preco'>
                    {promocao && (
                        <input 
                            style={{width: '30px', height: '20px'}}
                            type='checkbox'
                            defaultChecked={true}
                            onChange={(e)=>{
                                const checked = e.target.checked;
                                if(checked) {
                                    setPromocaoValue(true);
                                    if (user.geo_id === 1) {
                                        setValue('preco', '3 por 10,00');
                                    } else {
                                        setValue('preco', '4 por 12,00');
                                    }
                                } else {
                                    setPromocaoValue(false);
                                    setValue('preco', '0,00');
                                };
                            }}
                            value={promocaoValue}
                        />
                    )}
                    {!promocaoValue && 'R$'}
                    <input 
                        maxLength='6'
                        placeholder='0,00'
                        {...register('preco')}
                        onChange={(e)=>{
                            const valDigitado = moneyMask(e.target.value);
                            setValue('preco', `${valDigitado}`);
                        }}
                    />
                    {errors.preco && <span className='input-error'>{errors.preco.message}</span>}
                </InputContainer>
                <AdicionarButton>
                    adicionar
                </AdicionarButton>
            </FormContainer>
            <TableContainer>
                <CustomTable header={['#','Produto', 'Volume', 'Preço', 'Editar/Excluir']} message='Você ainda não adicionou um produto.' isLoading={pedidoBebidasLoading}>
                    {pedidoBebidasOrdenadas.map((pedidoBebida, index) => (
                        <PedidoBebidaTr 
                            key={pedidoBebida.id}
                            index={index}
                            bebida={pedidoBebida}
                            pedido={pedido}
                            buscarPedidoBebidas={buscarPedidoBebidas}
                            pedidoBebidas={pedidoBebidasOrdenadas}
                            setPedidoBebidasLoading={setPedidoBebidasLoading}
                        />
                    ))}
                </CustomTable>
            </TableContainer>
        </PedidoCategoriaBebidasContainer>
    );
};

export default PedidoCategoriaBebidas;